export const PERMISSIONS_MENU_GROUPS = {
    USER_SETTINGS: 'users:self-get, users:self-modify | tfa:search',
    MY_COMPANIES:
        'companies:get | settings:get | users:search, roles:search | roles:search | saml-settings:search, saml-settings:create, saml-settings:modify, saml-settings:delete',
    MY_COMPANY_USERS: [
        'users:search, roles:search',
        'roles:search, users:create',
        'roles:create, permission-policies:search',
        'roles:search',
        'permission-policies:search',
        'permission-policies:create',
    ].join(' | '),
    MY_BILLING:
        'licenses:get, company-counters:get | company-payment-sources:get, company-payment-sources:modify | license-transactions:search, companies:get',
    AUDIT: 'requests:search | requests:brand-search | requests:global-search',
    BRAND_STYLES:
        'view-settings-images:brand-create | view-settings-images:brand-search | view-settings-images:brand-create' +
        ' | view-settings-images:brand-search',
    MY_BRAND: 'brands:get, brands:update',
    BRAND_PROFILE: 'brands:get, brands:update',
    BRAND_USERS: [
        'users:brand-search, roles:brand-search',
        'roles:brand-search, users:brand-create',
        'roles:brand-create, permission-policies:brand-search',
        'roles:brand-search',
        'permission-policies:brand-search',
        'permission-policies:brand-create',
    ].join(' | '),
    GLOBAL_USERS: [
        'users:global-search, roles:global-search',
        'roles:global-search, users:global-create',
        'roles:global-create, permission-policies:global-search',
        'roles:global-search',
        'permission-policies:global-search',
        'permission-policies:global-create',
    ].join(' | '),
    VIEW_SETTINGS_IMAGES: 'view-settings-images:brand-create, view-settings-images:brand-search',
    VIEW_SETTINGS: 'view-settings:brand-create, view-settings:brand-get',
    BRAND: 'brands:global-search',
    DASHBOARD: 'stats:search | charges:get-statistics | subscriptions:search',
    DASHBOARD_COUNTERS: 'customers:search, gateways:search, subscriptions:search, notifications:search',
    GATEWAYS: 'gateways:search | gateways:create',
    REGISTRATIONS_SERVICES: 'registrations:search | registrations:create',
    SERVICES: 'services:search | services:create',
    GATEWAYS_SERVICES_CREATE: 'gateways:create | services:create',
    SERVICES_PROXY_LINKS: 'services:get-proxy-links',
    ROUTING:
        'routing:search,routing-rules:search | routing:create,routing-rules:create | routing-rules:analysis | rejects-rules:create | rejects-rules:search | rejects-rules:analysis',
    VAULTS: 'payment-sources-vault:search | vault-token-reference-configurations:get, vault-token-reference-configurations:get-available-strategies',
    CUSTOMERS: 'customers:search | customers:create',
    SUBSCRIPTIONS: 'subscriptions:search | subscriptions:create | settings:get, settings:modify',
    CHARGES: 'charges:search | charges:create',
    TRANSACTIONS: 'charges:brand-search',
    TRANSFERS: 'transfers:search | transfers:create',
    NOTIFICATIONS:
        'notifications:search | notifications:create | notification-logs:search | notification-templates:search | notification-services:search | notification-services:create',
    MERCHANTS: 'companies:brand-search | companies:brand-create',
    LIMIT_PROFILE: 'limit-profiles:global-create | limit-profiles:global-search',
    ACCESS_TOKENS: 'access-tokens:brand-search | access-tokens:search',
    SHARED_GROUPS:
        'shared-groups:brand-get | shared-groups:brand-search | shared-groups:brand-create | shared-groups:brand-modify' +
        '| shared-groups:brand-delete | shared-groups:brand-company-create | shared-groups:brand-company-delete',
    SHARED_GROUPS_CREATE: 'shared-groups:brand-create, shared-groups:brand-company-create',
    SHARED_GROUPS_MANAGE:
        'shared-groups:brand-get, shared-groups:brand-search, shared-groups:brand-modify' +
        ', shared-groups:brand-company-create, shared-groups:brand-company-delete',
    LOGIN_AS_BRAND: 'auth:global-create-spawn',
    RESTRICTIONS: 'restrictions:brand-search, restrictions:brand-get-available-permissions',
    LOGIN_AS_MERCHANT: 'auth:brand-create-spawn',
    VAULT_DISPLAY: 'vault-displays:search | vault-displays:create',
    VAULT_DISPLAY_ADD_NEW: 'vault-displays:create | vault-displays:create-request | vault-displays:get-card-info',
    VAULT_DISPLAY_VIEW: 'vault-displays:get | vault-displays:search | vault-displays:get-card-info',
    PROXY: 'fetches:search',
    STRIPE_ACCOUNT_SEARCH: 'gateway-accounts:search',
    STRIPE_ACCOUNT_CREATE: 'gateway-accounts:create',
    STRIPE_ACCOUNT_DELETE: 'gateway-accounts:delete',
    STRIPE_ACCOUNT_VIEW_MODIFY: 'gateway-accounts:get, gateway-accounts:modify',
    FEE_PROFILES: 'fee-profiles:brand-search | fee-profiles:brand-modify',
    STORAGE_SERVICES:
        'storage-services:search | storage-services:create | storage-services:brand-search | storage-services:brand-create',
    BRAND_SERVICES: 'storage-services:brand-search | storage-services:brand-create',
    FEE_PROFILE_SEARCH: 'fee-profiles:brand-search',
    THREE_DOMAIN_SECURE: 'services:search | services:create',
    FEATURES_OPTIONS: 'features-options:brand-search | features-options:brand-update',
    SCHEMA_REGISTRY:
        'schema-registries:global-search | schema-registries:global-search-available | schema-registries:global-apply | schema-registries:global-rollback',
    SCHEMA_REGISTRY_AVAILABLE: 'schema-registries:global-search-available',
    SCHEMA_REGISTRY_APPLIED: 'schema-registries:global-search',
    REGISTRATION_SERVICE: 'services:search | services:create',
    ENCRYPTION_SERVICES:
        'key-management:search | key-management:create-pgp | key-management:brand-search | key-management:brand-create-pgp | key-management:brand-create-ssh',
    GLOBAL_SAML_SETTINGS:
        'saml-settings:global-search | saml-settings:global-create | saml-settings:global-modify | saml-settings:global-delete',
    CHECKOUT:
        'checkouts-templates:search | checkouts-templates:create, checkouts:get-versions, checkouts-templates:get-schemas | checkouts:create-intent, checkouts:get-versions, checkouts-templates:search | checkouts:search',
};

export const PAGE_TABS_MANDATORY_PERMISSIONS = {
    RULES: [
        'rules:search',
        'rules:get',
        'rules:modify',
        'rules:create',
        'rules:change_priority',
        'rules:update',
        'rules:delete',
        'analysis:charge',
    ],
    SERVICES_ADD_NEW: ['gateways:create'],
    CONNECTED_SERVICES: ['gateways:search'],
    REGISTRATIONS_ADD_NEW: ['registrations:create'],
    REGISTRATIONS_CONNECTED_SERVICES: ['registrations:search'],
    REGISTRATIONS_UPDATE: ['registrations:modify'],
    REGISTRATIONS_REVOKE: ['registrations:revoke'],
    VAULT_VIEW_ALL: ['payment-sources-vault:search'],
    VAULT_REFERENCE_CONFIGURATION: [
        'vault-token-reference-configurations:get',
        'vault-token-reference-configurations:get-available-strategies',
    ],
    CUSTOMERS_VIEW_ALL: ['customers:search'],
    CUSTOMERS_ADD_NEW: ['customers:create'],
    SUBSCRIPTIONS_VIEW_ALL: ['subscriptions:search'],
    SUBSCRIPTIONS_ADD_NEW: ['subscriptions:create'],
    SUBSCRIPTIONS_SETTINGS: ['settings:get', 'settings:modify'],
    CHARGES_ADD_NEW: ['charges:create'],
    CHARGES_VIEW_ALL: ['charges:search'],
    TRANSACTIONS_VIEW_ALL: ['charges:brand-search'],
    TRANSFERS_VIEW_ALL: ['transfers:search'],
    TRANSFERS_ADD_NEW: ['transfers:create'],
    NOTIFICATION_VIEW_ALL: ['notifications:search'],
    NOTIFICATION_ADD_NEW: ['notifications:create'],
    NOTIFICATION_HISTORY: ['notification-logs:search'],
    NOTIFICATION_TEMPLATE: [
        'notification-templates:search',
        'notification-template-variables:get',
        'notification-templates:get',
        'notification-templates:modify',
        'notification-templates:create',
        'notification-templates:delete',
    ],
    VAULT_DISPLAY_VIEW_ALL: ['vault-displays:search'],
    VAULT_DISPLAY_ADD_NEW: ['vault-displays:create'],
    FETCH: ['fetches:search'],
    USER_DETAILS: ['users:self-get', 'users:self-modify'],
    TFA: ['tfa:search'],
    MY_COMPANY_PROFILE: ['companies:get'],
    MY_COMPANY_USERS: ['users:search', 'users:create'],
    MY_COMPANY_ROLES: ['roles:search', 'roles:create'],
    MY_COMPANY_SETTINGS: ['settings:get'],
    API_SETTINGS: ['remote-action-configs:get', 'access-tokens:search', 'version:get'],
    SAML_SETTINGS: ['saml-settings:search', 'saml-settings:create', 'saml-settings:modify', 'saml-settings:delete'],
    BRAND_SAML_SETTINGS: [
        'saml-settings:brand-search',
        'saml-settings:brand-create',
        'saml-settings:brand-modify',
        'saml-settings:brand-delete',
    ],
    GLOBAL_SAML_SETTINGS: [
        'saml-settings:global-search',
        'saml-settings:global-create',
        'saml-settings:global-modify',
        'saml-settings:global-delete',
    ],
    FEE_PROFILE_SEARCH: ['fee-profiles:brand-search'],
    FEE_PROFILE_EDIT: ['fee-profiles:brand-modify'],
    FEE_RULE_EDIT: [
        'fee-profiles:brand-create-rule',
        'fee-profiles:brand-modify-rule',
        'fee-profiles:brand-update-rule',
        'fee-profiles:brand-change-priority-rule',
    ],
    FEE_PROFILE_RELEASE: ['fee-profiles:brand-release-draft'],
    FEE_PROFILE_CLONE: ['fee-profiles:brand-clone'],
    FEE_PROFILE_DELETE: ['fee-profiles:brand-delete'],
    FEE_PROFILE_ANALYZE: ['fee-profiles:brand-analyze'],
    FEE_HISTORY_MANAGE: ['fee-histories:brand-search'],
    FEE_HISTORY_PERIOD_SEARCH: ['fee-periods:brand-search'],
    FEE_HISTORY_INTERRUPT: ['fee-periods:brand-delete'],
    FEE_HISTORY_GET_REPORT: ['fee-reports:brand-get-report-link'],
    FEE_HISTORY_APPLY_PERIOD: ['fee-periods:brand-create'],
    FEE_HISTORY_CREATE: ['fee-histories:brand-create'],
    BRAND_USERS_USERS: ['users:brand-create', 'users:brand-search'],
    BRAND_USERS_ROLES: ['roles:brand-search', 'roles:brand-create'],
    GLOBAL_USERS_USERS: ['users:global-create', 'users:global-search'],
    GLOBAL_USERS_ROLES: ['roles:global-search', 'roles:global-create'],
    GLOBAL_USERS_PERMISSION_POLICIES: ['permission-policies:global-search', 'permission-policies:global-create'],
    BRAND_USERS_PERMISSION_POLICIES: ['permission-policies:brand-search', 'permission-policies:brand-create'],
    USERS_PERMISSION_POLICIES: ['permission-policies:search', 'permission-policies:create'],
    REPORT_HISTORY: ['resource-reports:search', 'resource-reports:brand-search'],
    REPORT_DELIVERY: ['resource-reports:create', 'resource-reports:brand-create'],
    BULK_CHARGES: ['bulk-charges:search'],
    BRAND_CONNECTED_SERVICES: ['storage-services:brand-search'],
    BRAND_SERVICES_ADD_NEW: ['storage-services:brand-create'],
    CHECKOUT_TEMPLATES:
        'checkouts-templates:search | checkouts-templates:create, checkouts:get-versions, checkouts-templates:get-schemas',
    CHECKOUT_PREVIEW: ['checkouts:create-intent', 'checkouts:get-versions', 'checkouts-templates:search'],
    CHECKOUT_HISTORY: ['checkouts:search'],
};

export enum PERMISSION_TYPE {
    ALLOWING = 'allowing',
    FORBIDDING = 'forbidding',
}

export enum SCOPE {
    GLOBAL = 'global',
    BRAND = 'brand',
    COMPANY = 'company',
    USER = 'user',
}
