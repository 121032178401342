export const USER_MENU_LINKS = {
    ACCOUNT: '/account/personal-details',
    AUDIT: '/account/audit',
    COMPANY: '/my-company/profile',
    COMPANY_USERS: '/company/users',
    BILLING_PLANS: '/billing/plan',
    ACCESS_TOKENS: '/account/access-tokens',
    BRAND_STYLES: '/brand-styles',
    MY_BRAND: '/my-brand/',
    BRAND_USERS: '/brand-users',
    GLOBAL_USERS: '/global-users',
    SHARED_GROUPS: '/shared-groups',
    SECURITY: '/my-company/security',
    SSO: '/my-company/saml',
    SCHEMA_REGISTRY: '/schema-registries/available',
};

export const SIDEBAR_MENU_LINK = {
    GLOBAL_USERS: '/global-users/users',
    GLOBAL_ROLES: '/global-users/roles',
    GLOBAL_PERMISSION_POLICIES: '/global-users/permission-policies',
    BRAND_USERS: '/brand-users/users',
    BRAND_ROLES: '/brand-users/roles',
    BRAND_PERMISSION_POLICIES: '/brand-users/permission-policies',
    STATISTIC: '/dashboard/statistics',
    COMPANY: '/my-company/profile',
    SSO: '/my-company/saml',
    BRAND_SSO: '/my-brand/saml',
    GLOBAL_SSO: '/my-global/saml',
    SECURITY: '/my-company/security',
    SETTINGS: '/my-company/settings',
    ROLES: '/company/roles',
    USERS: '/company/users',
    TRANSFERS: '/transfers',
    TRANSFERS_CREATE: '/transfers/create',
    GLOBAL_BRANDS: '/brands/all',
    BRAND_MERCHANTS: '/merchants/all',
    SCHEMA_REGISTRY_APPLIED: '/schema-registries/applied',
    SCHEMA_REGISTRY_AVAILABLE: '/schema-registries/available',
    PERMISSION_POLICIES: '/company/permission-policies',
    COMPANY_WELCOME: '/dashboard/welcome',
    CHECKOUT_TEMPLATES: '/checkout/templates',
    JOURNEY: '/checkout/journey',
    PREVIEW: '/checkout/preview',
};
