import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { AuthGuard } from '../auth/guard/auth.guard';
import { AuthChildGuard } from '../auth/guard/auth-child.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NoPermissionsPageComponent } from './components/shared/no-permissions-page/no-permissions-page.component';
import { PaydockUrlSegments } from './helper/constants/url-segments.constants';

const routes: Route[] = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthChildGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'gateways', loadChildren: () => import('../services/services.module').then(m => m.ServicesModule) },
            {
                path: 'registrations',
                loadChildren: () => import('../registrations/registrations.module').then(m => m.RegistrationsModule),
            },
            {
                path: 'customers',
                loadChildren: () => import('../customers/customers.module').then(m => m.CustomersModule),
            },
            { path: 'vault', loadChildren: () => import('../vault/vault.module').then(m => m.VaultModule) },
            {
                path: 'subscriptions',
                loadChildren: () => import('../subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
            },
            { path: 'account', loadChildren: () => import('../account/account.module').then(m => m.AccountModule) },
            {
                path: 'billing',
                loadChildren: () => import('../billing/billing-routing.module').then(m => m.BillingRoutingModule),
            },
            { path: 'rules', loadChildren: () => import('../rules/rules.module').then(m => m.RulesModule) },
            { path: 'charges', loadChildren: () => import('../charges/charges.module').then(m => m.ChargesModule) },
            {
                path: 'limit-profiles',
                loadChildren: () => import('../limit-profiles/limit-profiles.module').then(m => m.LimitProfilesModule),
            },
            {
                path: 'merchants',
                loadChildren: () => import('../merchants/merchants.module').then(m => m.MerchantsModule),
            },
            {
                path: 'transactions',
                loadChildren: () => import('../transactions/transactions.module').then(m => m.TransactionsModule),
            },
            { path: 'brands', loadChildren: () => import('../brands/brands.module').then(m => m.BrandsModule) },
            {
                path: 'brand-users',
                loadChildren: () => import('../brand-users/brand-users.module').then(m => m.BrandUsersModule),
            },
            {
                path: 'brand-styles',
                loadChildren: () => import('../brand-styles/brand-styles.module').then(m => m.BrandStylesModule),
            },
            {
                path: 'my-brand',
                loadChildren: () => import('../my-brand/my-brand.module').then(m => m.MyBrandModule),
            },
            {
                path: 'my-global',
                loadChildren: () => import('../my-global/my-global.module').then(m => m.MyGlobalModule),
            },
            {
                path: PaydockUrlSegments.RemoteAction,
                loadChildren: () =>
                    import('../iframe-configuration/iframe-configuration-routing.module').then(
                        m => m.IframeConfigurationRoutingModule,
                    ),
            },
            {
                path: PaydockUrlSegments.MyCompany,
                loadChildren: () => import('../my-company/my-company.module').then(m => m.MyCompanyModule),
            },
            { path: 'company', loadChildren: () => import('../company/company.module').then(m => m.CompanyModule) },
            {
                path: 'shared-groups',
                loadChildren: () => import('../shared-groups/shared-groups.module').then(m => m.SharedGroupsModule),
            },
            {
                path: 'vault-display',
                loadChildren: () => import('../vault-display/vault-display.module').then(m => m.VaultDisplayModule),
            },
            { path: 'proxy', loadChildren: () => import('../proxy/proxy.module').then(m => m.ProxyModule) },
            {
                path: 'dashboard',
                loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'sub-accounts',
                loadChildren: () =>
                    import('../stripe-sub-accounts/stripe-sub-accounts.module').then(m => m.StripeSubAccountsModule),
            },
            {
                path: 'fee-profile',
                loadChildren: () => import('../fee-profile/fee-profile.module').then(m => m.FeeProfileModule),
            },
            {
                path: 'fee-history',
                loadChildren: () => import('../fee-history/fee-history.module').then(m => m.FeeHistoryModule),
            },
            {
                path: 'global-users',
                loadChildren: () => import('../global-users/global-users.module').then(m => m.GlobalUsersModule),
            },
            {
                path: 'notifications',
                loadChildren: () => import('../notifications/notifications.module').then(m => m.NotificationsModule),
            },
            { path: 'no-permissions', component: NoPermissionsPageComponent },
            {
                path: 'services',
                loadChildren: () => import('../brand-services/brand-services.module').then(m => m.BrandServicesModule),
            },
            {
                path: 'schema-registries',
                loadChildren: () =>
                    import('../schema-registries/schema-registries.module').then(m => m.SchemaRegistriesModule),
            },
            {
                path: 'transfers',
                loadChildren: () => import('../transfers/transfers-routing.module').then(m => m.TransfersRoutingModule),
            },
            {
                path: 'checkout',
                loadChildren: () => import('../checkout/checkout.module').then(m => m.CheckoutModule),
            },
        ],
    },
    {
        path: 'registration',
        loadChildren: () => import('../registration/registration.module').then(m => m.RegistrationModule),
    },
    { path: 'auth', loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule) },
    { path: 'saml', loadChildren: () => import('../saml/saml-login/saml-login.module').then(m => m.SamlLoginModule) },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class MainRoutingModule {}
