import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { ProgressbarModule } from 'ngx-bootstrap';
import { NgProgressModule } from 'ngx-progressbar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';

import { AjaxLoaderComponent } from './components/shared/ajax-loader/ajax-loader.component';
import { AppProgressBarComponent } from './components/shared/app-progress-bar/app-progress-bar.component';
import { BulletinComponent } from './components/shared/bulletin/bulletin.component';
import { CreditCardComponent } from './components/shared/credit-card/credit-card.component';
import { CustomFieldsComponent } from './components/shared/custom-fields/custom-fields.component';
import { CustomFieldsListComponent } from './components/shared/custom-fields-list/custom-fields-list.component';
import { DropdownActionsComponent } from './components/shared/dropdown-actions/dropdown-actions.component';
import { DropdownCountryComponent } from './components/shared/dropdown-country/dropdown-country.component';
import { DropdownCurrencyComponent } from './components/shared/dropdown-currency/dropdown-currency.component';
import { FormErrorComponent } from './components/shared/form-error/form-error.component';
import { FormErrorsComponent } from './components/shared/form-errors/form-errors.component';
import { FrontPageComponent } from './components/shared/front-page/front-page.component';
import { MessagePanelComponent } from './components/shared/message-panel/message-panel.component';
import { ToastrMessageComponent } from './components/shared/message-panel/toastr-message/toastr-message.component';
import { ModalConfirmComponent } from './components/shared/modal-confirm/modal-confirm.component';
import { ModalInfoComponent } from './components/shared/modal-info/modal-info.component';
import { ModalRemoveComponent } from './components/shared/modal-remove/modal-remove.component';
import { NoPermissionsPageComponent } from './components/shared/no-permissions-page/no-permissions-page.component';
import { PaginatorComponent } from './components/shared/paginator/paginator.component';
import { PaymentInformationComponent } from './components/shared/payment-information/payment-information.component';
import { ServiceLogoComponent } from './components/shared/service-logo/service-logo.component';
import { WhitelistChipsComponent } from './components/shared/whitelist-chips/whitelist-chips.component';
import { ClickToCopyDirective } from './directive/click-to-copy.directive';
import { ConfirmPasswordDirective } from './directive/confirm-password.directive';
import { ErrorDirective } from './directive/error.directive';
import { InviteValidatorDirective } from './directive/invite.validation.directive';
import { MainDirectiveModule } from './directive/main-directive.module';
import { MaxValidator } from './directive/max.directive';
import { MinValidator } from './directive/min.directive';
import { PanForbiddenValidator } from './directive/pan-forbidden.directive';
import { PermissionsDirective } from './directive/permissions.directive';
import { ShowBrandDirective } from './directive/show-brand.directive';
import { VaultTokenValidationDirective } from './directive/vault-token.validation.directive';
import { EqualValidator } from './helper/equal-validator.directive';
import { MaxSymbolsValidator } from './helper/max-characters-validator.directive';
import { OmitPipe } from './pipes/_omit.pipe';
import { BrandPipe } from './pipes/brand.pipe';
import { CardNumberLast4Pipe } from './pipes/card-number-last-4.pipe';
import { DateDifferencePipe } from './pipes/date-difference.pipe';
import { EqualMessageTypePipe } from './pipes/equal-message-type.pipe';
import { ExtractObjectValuePipe } from './pipes/extract-value.pipe';
import { FormControlPipe } from './pipes/form-control.pipe';
import { GetLogoImageByServiceTypePipe } from './pipes/get-logo-image-by-service-type.pipe';
import { IsEmptyPipe } from './pipes/is-empty.pipe';
import { LabelOfGatewayTypePipe } from './pipes/label-of-gateway-type.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { ServiceFilterPipe } from './pipes/service-filter.pipe';
import { UserNamePipe } from './pipes/user-name.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgProgressModule,
        MessagesModule,
        MessageModule,
        PaginatorModule,
        TextMaskModule,
        DialogModule,
        AutoCompleteModule,
        ProgressbarModule.forRoot(),
        MatFormFieldModule,
        TooltipModule,
        MatChipsModule,
        ReactiveFormsModule,
        CalendarModule,
        MainDirectiveModule,
        GetLogoImageByServiceTypePipe,
    ],
    declarations: [
        MessagePanelComponent,
        ToastrMessageComponent,
        PaginatorComponent,
        AjaxLoaderComponent,

        FormErrorsComponent,
        FormErrorComponent,
        DropdownActionsComponent,
        DropdownCountryComponent,
        DropdownCurrencyComponent,
        InviteValidatorDirective,

        ServiceLogoComponent,
        LabelOfGatewayTypePipe,
        ReplacePipe,
        CreditCardComponent,
        ServiceFilterPipe,
        OmitPipe,
        BulletinComponent,
        EqualValidator,
        BulletinComponent,
        DateDifferencePipe,
        PaymentInformationComponent,
        DateDifferencePipe,
        VaultTokenValidationDirective,
        DateDifferencePipe,
        CardNumberLast4Pipe,
        FormControlPipe,
        BrandPipe,
        UserNamePipe,
        ShowBrandDirective,
        MinValidator,
        MaxValidator,
        ModalConfirmComponent,
        ModalInfoComponent,
        ModalRemoveComponent,
        ConfirmPasswordDirective,
        PermissionsDirective,
        ErrorDirective,
        ExtractObjectValuePipe,
        CustomFieldsComponent,
        CustomFieldsListComponent,
        AppProgressBarComponent,
        EqualMessageTypePipe,
        WhitelistChipsComponent,
        ClickToCopyDirective,
        FrontPageComponent,
        MaxSymbolsValidator,
        NoPermissionsPageComponent,
        FrontPageComponent,
        PanForbiddenValidator,
        IsEmptyPipe,
    ],
    exports: [
        MessagePanelComponent,
        ToastrMessageComponent,
        PaginatorComponent,
        AjaxLoaderComponent,

        FormErrorsComponent,
        FormErrorComponent,
        DropdownActionsComponent,
        DropdownCountryComponent,
        DropdownCurrencyComponent,

        ServiceLogoComponent,
        LabelOfGatewayTypePipe,
        ReplacePipe,
        CreditCardComponent,
        ServiceFilterPipe,
        OmitPipe,
        BulletinComponent,
        EqualValidator,
        BulletinComponent,
        DateDifferencePipe,
        PaymentInformationComponent,
        DateDifferencePipe,
        InviteValidatorDirective,
        DateDifferencePipe,
        VaultTokenValidationDirective,
        DateDifferencePipe,
        CardNumberLast4Pipe,
        FormControlPipe,
        BrandPipe,
        UserNamePipe,
        ShowBrandDirective,
        MinValidator,
        MaxValidator,
        ModalConfirmComponent,
        ModalInfoComponent,
        ModalRemoveComponent,
        ConfirmPasswordDirective,
        PermissionsDirective,
        UserNamePipe,
        ErrorDirective,
        ExtractObjectValuePipe,
        CustomFieldsComponent,
        CustomFieldsListComponent,
        AppProgressBarComponent,
        EqualMessageTypePipe,
        WhitelistChipsComponent,
        ClickToCopyDirective,
        FrontPageComponent,
        MaxSymbolsValidator,
        NoPermissionsPageComponent,
        FrontPageComponent,
        CalendarModule,
        PanForbiddenValidator,
        IsEmptyPipe,
    ],
})
export class MainSharedModule {}
