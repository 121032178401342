<div class="large-2 columns full-height s-app-left-nav-col no-padding show-for-large-up sidebar not_print_element">
    <div class="sidebar-list">
        <div class="">
            <ul>
                <li id="firstStop">
                    <div class="avatar-holder">
                        <div class="avatar avatar-sidebar">
                            <a (click)="logoClick()">
                                <img
                                    src="/images/white_logo.png"
                                    onerror="this.src='/v2/assets/images/default.png';"
                                    [alt]="logoAltText" />
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="sidebar_list sub_account_block" *ngIf="subAccount.isSubAccount">
        <p>{{ subAccount.message }}</p>
        <button class="back_to_button" (click)="switchBack()">
            {{ subAccount.backTo }}
        </button>
    </div>

    <div class="menu-scroll">
        <div role="navigation" aria-label="Main Navigation" class="sidebar-list">
            <ul>
                <li>
                    <a class="username-link" (click)="hidedUserMenu = !hidedUserMenu">
                        <span class="user-name" title="{{ accountName }}">{{ this.accountName }}</span>
                        <i *ngIf="!hidedUserMenu" class="fa fa-sort-desc animated ng-rotateIn"></i>
                        <i *ngIf="hidedUserMenu" class="fa fa-caret-left animated ng-rotateIn"></i>
                    </a>
                    <div class="user-menu" [hidden]="hidedUserMenu">
                        <ul>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.USER_SETTINGS"
                                routerLinkActive="active">
                                <a *ngIf="!subAccount.isSubAccount" [routerLink]="USER_MENU_LINKS.ACCOUNT"
                                    >User Settings</a
                                >
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.SCHEMA_REGISTRY"
                                routerLinkActive="active">
                                <a [routerLink]="USER_MENU_LINKS.SCHEMA_REGISTRY">Schema Registry</a>
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.GLOBAL_SAML_SETTINGS"
                                routerLinkActive="active">
                                <a [routerLink]="SIDEBAR_MENU_LINK.GLOBAL_SSO">My Global</a>
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.GLOBAL_USERS"
                                routerLinkActive="active">
                                <a [routerLink]="USER_MENU_LINKS.GLOBAL_USERS">Global Users</a>
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.SHARED_GROUPS"
                                routerLinkActive="active">
                                <a [routerLink]="USER_MENU_LINKS.SHARED_GROUPS">Shared Groups</a>
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.MY_COMPANIES"
                                [class.active]="
                                    routeIsEquivalent('/my-company/saml') || routeIsEquivalent('/my-company/security')
                                ">
                                <a [routerLink]="USER_MENU_LINKS.COMPANY">My company</a>
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.MY_COMPANY_USERS"
                                routerLinkActive="active">
                                <a [routerLink]="USER_MENU_LINKS.COMPANY_USERS">Company Users</a>
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.BRAND_STYLES"
                                routerLinkActive="active">
                                <a [routerLink]="USER_MENU_LINKS.BRAND_STYLES">Brand styles</a>
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.MY_BRAND"
                                routerLinkActive="active">
                                <a [routerLink]="USER_MENU_LINKS.MY_BRAND">My brand</a>
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.BRAND_USERS"
                                routerLinkActive="active">
                                <a [routerLink]="USER_MENU_LINKS.BRAND_USERS">Brand users</a>
                            </li>
                            <ng-container *showBrand="BRAND.PAYDOCK">
                                <li
                                    *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.MY_BILLING"
                                    routerLinkActive="active">
                                    <a [routerLink]="USER_MENU_LINKS.BILLING_PLANS">My billing</a>
                                </li>
                            </ng-container>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.ACCESS_TOKENS"
                                routerLinkActive="active">
                                <a [routerLink]="USER_MENU_LINKS.ACCESS_TOKENS">Access tokens</a>
                            </li>
                            <li
                                *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.AUDIT"
                                routerLinkActive="active">
                                <a [routerLink]="USER_MENU_LINKS.AUDIT">Audit</a>
                            </li>
                            <li>
                                <a (click)="logOut()">Log out</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.DASHBOARD" routerLinkActive="active">
                    <a routerLink="/dashboard/statistics" class="sidebar-item">
                        <i class="icon-poll"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li *ifAllow="true; permission: PERMISSIONS_MENU_GROUPS.BRAND" routerLinkActive="active">
                    <a routerLink="/brands" class="sidebar-item">
                        <i class="icon-brand"></i>
                        <span>Brands</span>
                    </a>
                </li>
                <li *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.MERCHANTS" routerLinkActive="active">
                    <a routerLink="/merchants" class="sidebar-item">
                        <i class="icon-basket"></i>
                        <span>Merchants</span>
                    </a>
                </li>
                <ng-container *ngIf="scope === SCOPE.BRAND">
                    <li
                        *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.BRAND_SERVICES"
                        routerLinkActive="active">
                        <a routerLink="/services" class="sidebar-item">
                            <i class="icon-black-plug-head"></i>
                            <span>Services</span>
                        </a>
                    </li>
                </ng-container>
                <ng-container *showBrand="BRAND.PAYDOCK">
                    <li
                        id="sixthStop"
                        *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.GATEWAYS"
                        routerLinkActive="active">
                        <a routerLink="/gateways" class="sidebar-item">
                            <i class="icon-black-plug-head"></i>
                            <span>Services</span>
                        </a>
                    </li>
                    <li
                        *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.REGISTRATIONS_SERVICES"
                        routerLinkActive="active">
                        <a routerLink="/registrations" class="sidebar-item">
                            <i class="icon-registration"></i>
                            <span>Registrations</span>
                        </a>
                    </li>
                    <li
                        class="sidebar-item"
                        routerLinkActive="active"
                        *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.CHECKOUT">
                        <a routerLink="/checkout">
                            <i class="svg-icon">
                                <svg
                                    focusable="false"
                                    id="template"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.3998 21.5998C18.3998 22.4835 19.1161 23.1998 19.9998 23.1998C20.8835 23.1998 21.5998 22.4835 21.5998 21.5998C21.5998 20.7162 20.8835 19.9998 19.9998 19.9998C19.1161 19.9998 18.3998 20.7162 18.3998 21.5998Z"
                                        fill="#191919" />
                                    <path
                                        d="M8.79981 19.9998C7.91615 19.9998 7.19981 20.7162 7.19981 21.5998C7.19981 22.4835 7.91615 23.1998 8.79981 23.1998C9.68346 23.1998 10.3998 22.4835 10.3998 21.5998C10.3998 20.7162 9.68346 19.9998 8.79981 19.9998Z"
                                        fill="#191919" />
                                    <path
                                        d="M23.1998 16.7998H5.5998L1.7598 3.9998H19.9998C21.7671 3.9998 23.1998 5.43249 23.1998 7.19981V16.7998Z"
                                        fill="#191919" />
                                    <path
                                        d="M0.799805 0.799805L1.7598 3.9998M1.7598 3.9998L5.59981 16.7998H23.1998V7.19981C23.1998 5.43249 21.7671 3.9998 19.9998 3.9998H1.7598ZM19.9998 23.1998C19.1162 23.1998 18.3998 22.4835 18.3998 21.5998C18.3998 20.7162 19.1162 19.9998 19.9998 19.9998C20.8835 19.9998 21.5998 20.7162 21.5998 21.5998C21.5998 22.4835 20.8835 23.1998 19.9998 23.1998ZM7.19981 21.5998C7.19981 20.7162 7.91615 19.9998 8.79981 19.9998C9.68346 19.9998 10.3998 20.7162 10.3998 21.5998C10.3998 22.4835 9.68346 23.1998 8.79981 23.1998C7.91615 23.1998 7.19981 22.4835 7.19981 21.5998Z" />
                                </svg>
                            </i>
                            <span>Checkout</span>
                        </a>
                    </li>
                    <li
                        *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.ROUTING"
                        routerLinkActive="active">
                        <a routerLink="/rules" class="sidebar-item">
                            <i class="icon-arrows"></i>
                            <span>Rules</span>
                        </a>
                    </li>
                </ng-container>
                <li *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.VAULTS" routerLinkActive="active">
                    <a routerLink="/vault" class="sidebar-item">
                        <i class="icon-balance"></i>
                        <span>Vault</span>
                    </a>
                </li>
                <li *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.CUSTOMERS" routerLinkActive="active">
                    <a routerLink="/customers" class="sidebar-item">
                        <i class="icon-social"></i>
                        <span>Customers</span>
                    </a>
                </li>
                <li
                    *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.SUBSCRIPTIONS"
                    routerLinkActive="active">
                    <a routerLink="/subscriptions" class="sidebar-item">
                        <i class="icon-autorenew"></i>
                        <span>Subscriptions</span>
                    </a>
                </li>
                <li
                    *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.TRANSACTIONS"
                    routerLinkActive="active">
                    <a routerLink="/transactions" class="sidebar-item">
                        <i class="icon-updates"></i>
                        <span>Transactions</span>
                    </a>
                </li>
                <li
                    *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.FEE_PROFILES"
                    routerLinkActive="active">
                    <a routerLink="/fee-profile" class="sidebar-item">
                        <i class="svg-icon">
                            <svg focusable="false">
                                <use xlink:href="#fee-profile"></use>
                            </svg>
                        </i>
                        <span>Fee profiles</span>
                    </a>
                </li>
                <li *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.CHARGES" routerLinkActive="active">
                    <a routerLink="/charges" class="sidebar-item">
                        <i class="icon-updates"></i>
                        <span *showBrand="BRAND.PAYDOCK">Charges</span>
                        <span *showBrand="BRAND.KOVENA">Transactions</span>
                    </a>
                </li>
                <li *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.TRANSFERS" routerLinkActive="active">
                    <a routerLink="/transfers" class="sidebar-item">
                        <i class="icon-subdirectory"></i>
                        <span>Transfers</span>
                    </a>
                </li>
                <li
                    class="sidebar-item"
                    *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.NOTIFICATIONS"
                    routerLinkActive="active">
                    <a routerLink="/notifications" class="sidebar-item">
                        <i class="icon-chat"></i>
                        <span>Notifications</span>
                    </a>
                </li>
                <li
                    *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.LIMIT_PROFILE"
                    routerLinkActive="active">
                    <a routerLink="/limit-profiles" class="sidebar-item">
                        <i class="icon-risk"></i>
                        <span>Risk Profiles</span>
                    </a>
                </li>
                <li
                    class="sidebar-item"
                    *ifAllow="true; oneOfPermissionGroups: PERMISSIONS_MENU_GROUPS.VAULT_DISPLAY"
                    routerLinkActive="active">
                    <a routerLink="/vault-display">
                        <i class="icon-credit-card"></i>
                        <span>Vault Display</span>
                    </a>
                </li>
                <li
                    class="sidebar-item"
                    *ifAllow="true; permission: PERMISSIONS_MENU_GROUPS.PROXY"
                    routerLinkActive="active">
                    <a routerLink="/proxy">
                        <i class="icon-proxy"></i>
                        <span>Proxy</span>
                    </a>
                </li>
            </ul>
            <ng-container *ngIf="helpLinksAvailable">
                <hr />
                <div class="help-block">
                    <label id="help-links" class="help-block__label">
                        <i class="fa fa-info" aria-hidden="true"></i>
                        Need Help?
                    </label>
                    <nav aria-labelledby="help-links">
                        <ul class="help-block__list">
                            <li *ngFor="let link of viewSettings.lower_links" class="help-block__list__item">
                                <a class="help-block__list__hyperlink" href="{{ link.url }}" target="_blank">
                                    {{ link.name }}
                                    <i class="fa fa-external-link" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </ng-container>
            <ng-container *ngIf="personalSettingsAvailable">
                <hr />
                <div class="help-block">
                    <label id="personal-settings" class="help-block__label">
                        <i class="fa fa-cog" aria-hidden="true"></i>
                        Personal Settings
                    </label>
                    <nav aria-labelledby="personal-settings">
                        <ul class="help-block__list">
                            <li class="help-block__list__item">
                                <button
                                    aria-label="Open Cookie Consent Management Modal"
                                    class="help-block__list__button"
                                    type="button"
                                    (click)="showCookieConsentModal()">
                                    Cookie Consent
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<svg class="d-none" focusable="false" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <symbol id="fee-profile" width="28" height="21" viewBox="0 0 28 21">
            <path
                d="M10.5002 0C7.7847 0 5.58643 2.19828 5.58643 4.91379C5.58643 7.62931 7.7847 9.82759 10.5002 9.82759C13.2157 9.82759 15.414 7.62931 15.414 4.91379C15.414 2.19828 13.2157 0 10.5002 0Z" />
            <path
                d="M12.4139 10.3965H8.58627C4.73283 10.3965 1.60352 13.5258 1.60352 17.3792V20.2241C1.60352 20.6637 1.93972 20.9999 2.37938 20.9999H18.6208C19.0604 20.9999 19.3966 20.6637 19.3966 20.2241V17.3792C19.3966 13.5258 16.2673 10.3965 12.4139 10.3965Z" />
            <path
                d="M21 16.7999C23.3159 16.7999 25.1998 14.9158 25.1998 12.5999C25.1998 10.284 23.3159 8.3999 21 8.3999C18.684 8.3999 16.7998 10.284 16.7998 12.5999C16.7998 14.9158 18.684 16.7999 21 16.7999ZM20.5026 10.1312V10.0171C20.5026 9.7422 20.7251 9.5197 21 9.5197C21.2745 9.5197 21.4974 9.7422 21.4974 10.0171V10.1318C22.0893 10.2588 22.5347 10.7861 22.5347 11.4154C22.5347 11.69 22.3118 11.9128 22.0372 11.9128C21.7623 11.9128 21.5398 11.69 21.5398 11.4154C21.5398 11.24 21.3969 11.0971 21.2215 11.0971H20.7755C20.6001 11.0971 20.4571 11.24 20.4571 11.4154C20.4571 11.5159 20.5052 11.6114 20.5861 11.6711L21 11.9775L22.0054 12.7216C22.3357 12.966 22.5347 13.3569 22.5373 13.7681V13.7728C22.5396 14.1236 22.4053 14.4545 22.1586 14.7042C21.9756 14.8896 21.7468 15.0139 21.4974 15.0663V15.1827C21.4974 15.4576 21.2745 15.6801 21 15.6801C20.7251 15.6801 20.5026 15.4576 20.5026 15.1827V15.068C20.2598 15.0162 20.0366 14.8969 19.8562 14.7185C19.6065 14.4721 19.4679 14.1432 19.4659 13.7923C19.4643 13.5178 19.6855 13.2936 19.96 13.2919H19.9634C20.2366 13.2919 20.4591 13.5128 20.4608 13.7864C20.4614 13.958 20.5996 14.1027 20.7808 14.1027C21.0556 14.101 20.9532 14.1016 21.2261 14.1C21.4027 14.0987 21.5438 13.9554 21.5425 13.7794V13.7747C21.5418 13.6753 21.4937 13.5804 21.4135 13.5211L21 13.215L19.9942 12.4709C19.6613 12.2242 19.4623 11.8296 19.4623 11.4154C19.4623 10.7847 19.909 10.2572 20.5026 10.1312Z" />
            <path
                d="M24.5001 21C26.4301 21 28 19.4299 28 17.5C28 15.5701 26.4301 14 24.5001 14C22.5702 14 21 15.5701 21 17.5C21 19.4299 22.5702 21 24.5001 21ZM24.0856 15.4427V15.3477C24.0856 15.1186 24.2711 14.9332 24.5001 14.9332C24.7289 14.9332 24.9147 15.1186 24.9147 15.3477V15.4433C25.4079 15.5491 25.779 15.9885 25.779 16.5129C25.779 16.7417 25.5933 16.9274 25.3645 16.9274C25.1354 16.9274 24.95 16.7417 24.95 16.5129C24.95 16.3668 24.8309 16.2477 24.6847 16.2477H24.3131C24.1669 16.2477 24.0478 16.3668 24.0478 16.5129C24.0478 16.5967 24.0878 16.6763 24.1553 16.726L24.5001 16.9813L25.338 17.6014C25.6132 17.8051 25.779 18.1309 25.7813 18.4735V18.4774C25.7832 18.7697 25.6713 19.0455 25.4657 19.2536C25.3131 19.4081 25.1225 19.5117 24.9147 19.5553V19.6523C24.9147 19.8814 24.7289 20.0668 24.5001 20.0668C24.2711 20.0668 24.0856 19.8814 24.0856 19.6523V19.5567C23.8833 19.5136 23.6974 19.4141 23.547 19.2655C23.339 19.0602 23.2234 18.786 23.2218 18.4937C23.2204 18.2649 23.4047 18.0781 23.6335 18.0767H23.6363C23.864 18.0767 24.0494 18.2607 24.0508 18.4887C24.0514 18.6318 24.1665 18.7523 24.3175 18.7523C24.5465 18.7509 24.4612 18.7514 24.6886 18.7501C24.8357 18.749 24.9534 18.6296 24.9522 18.4829V18.479C24.9517 18.3961 24.9116 18.3171 24.8447 18.2676L24.5001 18.0126L23.662 17.3925C23.3845 17.1869 23.2187 16.8581 23.2187 16.5129C23.2187 15.9874 23.591 15.5477 24.0856 15.4427Z" />
        </symbol>
    </defs>
</svg>
